<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
          <b-overlay :show="loading">
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <b-tbody>
                    <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th style="width: 15%"  class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</th>
                      <th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</th>
                      <td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.name') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.name_bn : formData.name }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.mobile') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.mobile, { useGrouping: false }) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.email') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ formData.email }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.room_type') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.room_type_bn : formData.room_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.room_rent') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $n(formData.room_rent) }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.floor_no') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.floor_no : formData.floor_no }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.room_no') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <!-- <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.room_no : formData.room_no }}</td> -->
                      <td style="width: 30%">{{ formData.room_no }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.totalAmount') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $n(formData.total_rent) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.start_date') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ formData.start_date | dateFormat }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.end_date') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ formData.end_date | dateFormat }}</td>
                    </tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr/>
            <b-row>
              <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form lg="12" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Bill Generate Date" vid="bill_generate_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="bill_generate_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_venue.bill_generate_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.bill_generate_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Total Amount"  vid="total_rent" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="total_rent"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.totalAmount')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="total_rent"
                                v-model="formData.total_rent"
                                :placeholder="$t('globalTrans.totalAmount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Vat Tax"  vid="vat_tax">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="tax"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.vat_tax') + '(%)'}}
                              </template>
                              <b-form-input
                                id="rent_amount"
                                v-model="formData.tax"
                                :placeholder="$t('globalTrans.vat_tax') + '(%)'"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @input="paymentAmountUpdate()"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Service Charge" vid='service_charge' rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="service_charge"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_accommodation.service_charge')}}
                              </template>
                              <b-form-input
                                  id="service_charge"
                                  v-model="formData.service_charge"
                                  :placeholder="$t('elearning_accommodation.service_charge')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @input="paymentAmountUpdate()"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Other Deduction" vid='other_deduction' rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="other_deduction"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_accommodation.other_deduction')}}
                              </template>
                              <b-form-input
                                  id="other_deduction"
                                  v-model="formData.other_deduction"
                                  :placeholder="$t('elearning_accommodation.other_deduction')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @input="paymentAmountUpdate()"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Payment Amount"  vid="payment_amount" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="payment_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.payment_amount')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="payment_amount"
                                v-model="formData.payment_amount"
                                :placeholder="$t('globalTrans.payment_amount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
              </b-col>
            </b-row>
          </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{formData}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { bookingApplicationStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.gentFormData()
      this.formData = tmp
      this.formData.reservation_id = this.formData.id
      // this.formData.tax = tmp.tax
      this.formData.payment_status = 2
      if (this.formData.registration_for === 2) {
        const endDate = new Date(this.formData.end_date)
        const startDate = new Date(this.formData.start_date)

        // Calculate the time difference in milliseconds
        const timeDifference = endDate - startDate

        // Convert milliseconds to days (you can convert to other units as needed)
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        this.formData.service_charge = (daysDifference + 1) * 50
      } else {
        this.formData.service_charge = 0
      }
      this.formData.other_deduction = 0
      this.formData.tax_amount = this.formData.total_rent * (this.formData.tax / 100)
      this.formData.payment_amount = this.formData.total_rent + this.formData.tax_amount
      if (this.formData.bill_generate) {
        this.formData.bill_generate_id = this.formData.bill_generate.id
        this.formData.rent_amount = this.formData.bill_generate.rent_amount
        this.formData.bill_generate_date = this.formData.bill_generate.bill_generate_date
        this.formData.tax = this.formData.bill_generate.vat_tax
        this.formData.service_charge = this.formData.bill_generate.service_charge
        this.formData.other_deduction = this.formData.bill_generate.other_deduction
        this.formData.tax_amount = this.formData.bill_generate.tax_amount
        this.formData.payment_amount = this.formData.bill_generate.payment_amount
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        org_id: 0
      },
      officeTypeList: [],
      guestHouseList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
        this.officeTypeList = this.officeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
        this.guestHouseList = this.guestList(newValue)
    }
  },
  methods: {
    paymentAmountUpdate () {
      this.formData.tax_amount = this.calculatePercentage(this.formData.total_rent, this.formData.tax)
      const serviceAmount = parseFloat(this.formData.service_charge)
      const deductionAmount = parseFloat(this.formData.other_deduction)
      this.formData.payment_amount = (parseFloat(this.formData.total_rent) + parseFloat(this.formData.tax_amount) + serviceAmount + deductionAmount)
    },
    calculatePercentage (oldAvg, newAvg) {
      const totalPercentage = (parseFloat(oldAvg) * newAvg) / 100
      return totalPercentage
    },
    gentFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    officeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    guestList (officeId) {
       const guest = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
       if (officeId) {
         return guest.filter(item => item.office_type_id === officeId)
       }
       return guest
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${bookingApplicationStore}`, this.formData)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-5')
        // this.$router.push('/training-e-learning-service/venue/pending-venue-reservation')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
