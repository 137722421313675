<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.booking_confirmation')}} {{$t('globalTrans.search')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="mobile"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.mobile')}}
                        </template>
                        <b-form-input
                          id="mobile"
                          v-model="search.mobile"
                          :placeholder="$t('globalTrans.mobile')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_iabm.booking_confirmation')}} {{$t('globalTrans.list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(room_rent)="data">
                                          {{ $n(data.item.room_rent) }}
                                        </template>
                                        <template v-slot:cell(floor_no)="data">
                                          {{ $n(data.item.floor_no) }}
                                        </template>
                                        <template v-slot:cell(room_no)="data">
                                          {{ $n(data.item.room_no) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(start_date)="data">
                                          <span class="capitalize">{{ data.item.start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(end_date)="data">
                                          <span class="capitalize">{{ data.item.end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Make Payment" v-b-modal.modal-5 v-if="data.item.payment_status == 1" @click="edit(data.item)"><i class="fab fa-paypal"></i>
                                              <!-- {{$t('elearning_venue.make_payment')}} -->
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Receipt" v-b-modal.modal-6 v-if="data.item.payment_status == 2" @click="edit(data.item),deli(data.item)">
                                                <i class="fas fa-receipt"></i>
                                                <!-- {{$t('elearning_venue.receipt')}} -->
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="Booking View" v-b-modal.modal-4 @click="edit(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_accommodation.bill_generate')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <PaymentForm :id="editItemId" :key="editItemId" ref="form"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
          <Receipt :id="editItemId" :key="editItemId" ref="receipt"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Details from './Details'
import PaymentForm from './PaymentForm'
import Receipt from './Receipt'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { bookingApplicationList, circularPublicationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details,
        PaymentForm,
        Receipt
    },
    data () {
        return {
            search: {
              coordinator_id: 0,
              org_id: 0,
              training_type_id: 0,
              training_category_id: 0,
              training_title_id: 0,
              fiscal_year_id: 0
            },
            itemidd: 0,
            trainingTypeList: [],
            trainingTitleList: [],
            trainingCategoryList: []
        }
    },
    watch: {
      'search.org_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_type_id = 0
          this.trainingTypeList = this.getTypeList(newValue)
        } else {
          this.trainingTypeList = []
        }
      },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    computed: {
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      //  trainingTypeList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      // },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
        trainerEvaluationList: function () {
          return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
        },
        formTitle () {
            return this.$t('elearning_iabm.booking') + ' ' + this.$t('globalTrans.view')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
                { label: this.$t('elearning_config.organization'), class: 'text-center' },
                { label: this.$t('elearning_iabm.registration_for'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('globalTrans.mobile'), class: 'text-center' },
                { label: this.$t('elearning_config.room_type'), class: 'text-center' },
                { label: this.$t('elearning_config.floor_no'), class: 'text-center' },
                { label: this.$t('elearning_config.room_no'), class: 'text-center' },
                { label: this.$t('globalTrans.start_date'), class: 'text-center' },
                { label: this.$t('globalTrans.end_date'), class: 'text-center' },
                { label: this.$t('elearning_config.room_rent'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year_bn' },
                { key: 'org_bn' },
                { key: 'user_type_name_bn' },
                { key: 'name_bn' },
                { key: 'mobile' },
                { key: 'room_type_bn' },
                { key: 'floor_no' },
                { key: 'room_no' },
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'room_rent' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'org' },
                { key: 'user_type_name' },
                { key: 'name' },
                { key: 'mobile' },
                { key: 'room_type' },
                { key: 'floor_no' },
                { key: 'room_no' },
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'room_rent' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
      this.loadData()
      // this.search = Object.assign({}, this.search, {
      //   org_id: this.$store.state.dataFilters.orgId,
      //   fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      // })
      // this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
      getTypeList (orgId) {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (orgId) {
            return type.filter(item => item.org_id === parseInt(orgId))
          }
          return type
        }
      },
      deli (id) {
        this.itemidd = id.id
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, circularPublicationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, bookingApplicationList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const customItem = {}
          const userType = this.userTypeList.find(userTypeObj => userTypeObj.value === item.registration_for)
          customItem.user_type_name = userType?.text_en
          customItem.user_type_name_bn = userType?.text_bn

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const guestHouseObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(roomTypeList => parseInt(roomTypeList.value) === parseInt(item.guest_house_id))
          const guestHouseData = {}
          if (typeof guestHouseObj !== 'undefined') {
            guestHouseData.guest_house = guestHouseObj.text_en
            guestHouseData.guest_house_bn = guestHouseObj.text_bn
          } else {
            guestHouseData.guest_house = ''
            guestHouseData.guest_house_bn = ''
          }
          const roomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(roomTypeList => parseInt(roomTypeList.value) === parseInt(item.room_type_id))
          const roomTypeData = {}
          if (typeof roomTypeObj !== 'undefined') {
            roomTypeData.room_type = roomTypeObj.text_en
            roomTypeData.room_type_bn = roomTypeObj.text_bn
          } else {
            roomTypeData.room_type = ''
            roomTypeData.room_type_bn = ''
          }
          const roomObj = this.$store.state.TrainingElearning.commonObj.roomList.find(roomList => parseInt(roomList.value) === parseInt(item.room_id))
          const roomData = {}
          if (typeof roomObj !== 'undefined') {
            roomData.room_no = roomObj.text_en
          } else {
            roomData.room_no = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, roomTypeData, OfficeTypeData, roomData, guestHouseData, customItem)
        })
        return listData
      },
      pdfExport () {
        const tmpData = this.$store.state.list.find(item => item.id === this.itemidd)
        const acco = JSON.parse(JSON.stringify(tmpData))
        const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this, acco)
      }
    }
}
</script>
