import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import ReportHeading from '@/Utils/report-head-traning'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm, accommodation) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' }
            // { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
      // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      pdfContent.push({
          text: vm.$t('elearning_iabm.circular_publication'),
          style: 'krishi',
          alignment: 'center'
      })
      pdfContent.push({
          text: vm.$t('elearning_iabm.to'),
          style: 'lines',
          alignment: 'left'
      })
      pdfContent.push({
        text: vm.$t('elearning_config.fiscal_year') + ' ' + ':' + ' ' + accommodation.fiscal_year,
        style: 'lines',
        alignment: 'left'
      })
      pdfContent.push({
      text: vm.$t('globalTrans.organization') + ' ' + ':' + ' ' + accommodation.org,
      style: 'lines',
      alignment: 'left'
      })
      pdfContent.push({
        text: vm.$t('elearning_config.office_type') + ' ' + ':' + ' ' + accommodation.office_type,
        style: 'lines',
        alignment: 'left'
      })
      pdfContent.push({
          text: vm.$t('globalTrans.office') + ' ' + ':' + ' ' + accommodation.office,
          style: 'lines',
          alignment: 'left'
      })
      pdfContent.push({
        text: vm.$t('globalTrans.name') + ' ' + ':' + ' ' + accommodation.name,
        style: 'lines',
        alignment: 'left'
      })
      pdfContent.push({
      text: vm.$t('globalTrans.mobile') + ' ' + ':' + ' ' + accommodation.mobile,
      style: 'linestwo',
      alignment: 'left'
      })
      let allRows = []
      // table header
      // const headerRow1 = [
      //   { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
      //   { text: vm.$t('fertilizerConfig.dealer_name'), style: 'th', alignment: 'center' },
      //   { text: vm.$t('warehouse_config.fiscal_year'), style: 'th', alignment: 'center' },
      //   { text: vm.$t('pusti_mapping.month'), style: 'th', alignment: 'center' },
      //   { text: vm.$t('fertilizerSales.allocationType'), style: 'th', alignment: 'center' },
      //   { text: vm.$t('pump_install.requisition_date'), style: 'th', alignment: 'center' },
      //   { text: vm.$t('dealer_panel.requisition_amount'), style: 'th', alignment: 'center' }
      // ]
      allRows = [
        [
          { text: vm.$t('elearning_config.room_type'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_config.floor_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_config.room_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_config.room_rent'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.totalAmount'), style: 'th', alignment: 'center' }
        ]
      ]
        let newrow = [
            { text: accommodation.room_type, style: 'td', alignment: 'center' },
            { text: accommodation.floor_no, style: 'td', alignment: 'right' },
            { text: accommodation.room_no, style: 'td', alignment: 'right' },
            { text: vm.$n(accommodation.room_rent), style: 'td', alignment: 'right' },
            { text: vm.$n(accommodation.total_rent), style: 'td', alignment: 'right' }
        ]
        allRows.push(newrow)
        newrow = [
            { colSpan: 4, text: vm.$t('globalTrans.tax') + ' ' + '(%)', style: 'td', alignment: 'right' },
            { text: accommodation.floor_no, style: 'td', alignment: 'right' },
            { text: accommodation.room_no, style: 'td', alignment: 'right' },
            { text: vm.$n(accommodation.room_rent), style: 'td', alignment: 'right' },
            { text: accommodation.tax, style: 'td', alignment: 'right' }
        ]
        allRows.push(newrow)
        newrow = [
          { colSpan: 4, text: vm.$t('elearning_accommodation.service_charge'), style: 'td', alignment: 'right' },
          { text: accommodation.floor_no, style: 'td', alignment: 'right' },
          { text: accommodation.room_no, style: 'td', alignment: 'right' },
          { text: vm.$n(accommodation.room_rent), style: 'td', alignment: 'right' },
          { text: accommodation.service_charge, style: 'td', alignment: 'right' }
        ]
        allRows.push(newrow)
        newrow = [
          { colSpan: 4, text: vm.$t('elearning_accommodation.other_deduction'), style: 'td', alignment: 'right' },
          { text: accommodation.floor_no, style: 'td', alignment: 'right' },
          { text: accommodation.room_no, style: 'td', alignment: 'right' },
          { text: vm.$n(accommodation.room_rent), style: 'td', alignment: 'right' },
          { text: accommodation.other_deduction, style: 'td', alignment: 'right' }
        ]
        allRows.push(newrow)
        newrow = [
          { colSpan: 4, text: vm.$t('globalTrans.total'), style: 'td', alignment: 'right' },
          { text: accommodation.floor_no, style: 'td', alignment: 'right' },
          { text: accommodation.room_no, style: 'td', alignment: 'right' },
          { text: vm.$n(accommodation.room_rent), style: 'td', alignment: 'right' },
          { text: accommodation.payment_amount, style: 'td', alignment: 'right' }
        ]
      allRows.push(newrow)
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: '*',
          body: allRows
        }
      })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const footer = [
          [
            { text: vm.$t('globalTrans.date') + ' : ' + dateFormat(accommodation.bill_generate_date), alignment: 'left', style: 'lines' }
          ],
          [
            { text: vm.$t('globalTrans.thank_you'), alignment: 'left', style: 'lines' }
          ],
          [
            { text: 'MOA Software', alignment: 'left', style: 'lines' }
          ]
        ]
        pdfContent.push({
          table: {
            widths: ['100%'],
            body: footer
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: 7,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 10,
            margin: [0, 10, 0, 10]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          },
          lines: {
            fontSize: 8,
            margin: [0, 1, 0, 5],
            alignment: 'left'
          },
          linestwo: {
            fontSize: 8,
            margin: [0, 0, 0, 10],
            alignment: 'left'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
